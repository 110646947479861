<template>
    <div style="display: flex;justify-content: space-between;align-items: flex-start;flex-wrap: wrap">
        <div style="width: 100%;display: flex">
            <div class="block">
                <span class="demonstration"></span>
                <el-date-picker size="mini"
                                v-model="startDate"
                                align="right"
                                type="date"
                                placeholder="시작일 선택"
                                :picker-options="pickerOptions"
                                @change="setStartDate">
                </el-date-picker>
            </div>
            <div style="padding-left: 2px;padding-right: 2px">~</div>
            <div class="block">
                <span class="demonstration"></span>
                <el-date-picker size="mini"
                                v-model="endDate"
                                align="right"
                                type="date"
                                placeholder="마감일 선택"
                                :picker-options="pickerOptions"
                                @change="setEndDate">
                </el-date-picker>
            </div>
            <el-button type="primary" @click="search" size="mini" style="margin-left: 10px">검색</el-button>
        </div>
        <div style="width: 50%;min-height: 300px">
            <h5 style="background-color: #b3d8ff;line-height: 30px;padding: 0 5px">입금내역</h5>
            <p>총입금:{{rechageAmountTotal|comma}}</p>
            <el-table
                    :data="rechargeList"
                    style="width: 100%"
                    max-height="730"
                    border>
                <!--번호-->
                <el-table-column
                        label="번호"
                        width="100">
                    <template slot-scope="scope">
                        {{scope.row.id}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="날짜"
                        width="120">
                    <template slot-scope="scope">
                        {{scope.row.createTime|datef('MM-DD HH:mm')}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="금액"
                        width="120">
                    <template slot-scope="scope">
                        {{scope.row.amount|comma}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="보너스"
                        width="330">
                    <template slot-scope="scope">
                        <span v-if="scope.row.bonusin == managerConst.ApplyCash.APPLY_BONUS_NOT">[미지급] 롤링100%</span>

                        <span v-if="scope.row.bonusin == managerConst.ApplyCash.APPLY_BONUS_YES1"
                              class="text-primary">
                                                        <i class="fa fa-check-circle" style="color: grey">[스포츠 두폴더] 롤링100%</i>
                                                    </span>
                        <span v-if="scope.row.bonusin == managerConst.ApplyCash.APPLY_BONUS_YES2"
                              class="text-primary">
                                                        <i class="fa fa-check-circle" style="color: deepskyblue">[스포츠 세폴더이상] 롤링100%</i>
                                                    </span>
                        <span v-if="scope.row.bonusin == managerConst.ApplyCash.APPLY_BONUS_YES3"
                              class="text-primary">
                                                        <i class="fa fa-check-circle" style="color: grey">[스포츠 두폴더이상+미니게임(카지노/슬롯)] 롤링200%</i>
                                                    </span>
                        <span v-if="scope.row.bonusin == managerConst.ApplyCash.APPLY_BONUS_YES4"
                              class="text-primary">
                                                        <i class="fa fa-check-circle" style="color: grey">[카지노/슬롯/미니게임] 롤링200%</i>
                                                    </span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagePanel">
                <el-pagination
                        @size-change="rHandleSizeChange"
                        @current-change="rHandleCurrentChange"
                        :current-page="rPageNum"
                        :page-sizes="[5,50, 100, 150, 200]"
                        :page-size="rPageSize"
                        layout="total,  prev, pager, next"
                        :total="rPageTotal">
                </el-pagination>
            </div>
        </div>
        <div style="width: 50%;min-height: 300px">
            <h5 style="background-color: #b3d8ff;line-height: 30px;padding: 0 5px">출금내역</h5>
            <p>
                <span style="display: inline-block">총출금:{{exchangeAmountTotal|comma}}</span>
                <span style="display: inline-block;margin-left: 20px">손이익:{{(rechageAmountTotal - exchangeAmountTotal)|comma}} 원</span>
            </p>
            <el-table
                    :data="exchangeList"
                    style="width: 100%"
                    max-height="730"
                    border>
                <!--번호-->
                <el-table-column
                        label="번호"
                        width="80">
                    <template slot-scope="scope">
                        {{scope.row.id}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="날짜"
                        width="80">
                    <template slot-scope="scope">
                        {{scope.row.createTime|datef('MM-DD HH:mm')}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="금액"
                        width="80">
                    <template slot-scope="scope">
                        {{scope.row.amount|comma}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="롤링"
                        width="420">
                    <template slot-scope="scope" style="text-align: left">
                       {{scope.row.rollinginfo}}
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagePanel">
                <el-pagination
                        @size-change="eHandleSizeChange"
                        @current-change="eHandleCurrentChange"
                        :current-page="ePageNum"
                        :page-sizes="[20,50, 100, 150, 200]"
                        :page-size="ePageSize"
                        layout="total,  prev, pager, next"
                        :total="ePageTotal">
                </el-pagination>
            </div>
        </div>

    </div>
</template>

<script>
    import managerConst from "../../common/administrator/managerConst";
    import {getApplyList} from "../../network/manager/applyRequest";

    export default {
        name: "UserInfoApplyComp",
        data() {
            return {
                startDate: '',
                endDate: '',
                recharge: {},
                exchange: {},
                rechargeList: [],
                exchangeList: [],
                managerConst: managerConst,
                rPageNum: 1,
                rPageSize:20,
                rOrderBy: null,
                rPageTotal: 0,
                ePageNum: 1,
                ePageSize: 20,
                eOrderBy: null,
                ePageTotal: 0,

                rechageAmountTotal: 0, // 총입금액수
                rechageCountTotal: 0, //총입금개수
                exchangeAmountTotal: 0,//총출금액수
                exchangeCountTotal: 0,//총출금개수
                pickerOptions: {
                    shortcuts: [{
                        text: '오늘',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '어제',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '일주일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '15일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 14);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '30일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', date);
                        }
                    }]
                },

            }
        },
        props: {
            uid: {
                type: Number,
                default() {
                    return 0;
                }
            },

        },
        methods: {
            getRechargeList() {
                this.recharge.status = managerConst.ApplyCash.STATUS_COMPLITE
                this.recharge.applyType = managerConst.ApplyCash.ATYPE_RECHARGE
                this.recharge.startDate = this.startDate
                this.recharge.endDate = this.endDate
                this.recharge.userId = this.uid
                getApplyList(this.recharge, this.rPageNum, this.rPageSize).then(res => {
                    this.rechargeList = res.data.data.cashList
                    this.rechageAmountTotal = res.data.data.totalCash;
                    this.rechageCountTotal = res.data.data.totalCount;
                    this.rPageTotal = res.data.data.totalCount
                })
            },
            getExchangeList() {
                this.exchange.status = managerConst.ApplyCash.STATUS_COMPLITE
                this.exchange.applyType = managerConst.ApplyCash.ATYPE_EXCHANGE
                this.exchange.startDate = this.startDate
                this.exchange.endDate = this.endDate
                this.exchange.userId = this.uid
                getApplyList(this.exchange, this.ePageNum, this.ePageSize).then(res => {
                    this.exchangeList = res.data.data.cashList
                    this.exchangeAmountTotal = res.data.data.totalCash;
                    this.exchangeCountTotal = res.data.data.totalCount;
                    this.ePageTotal = res.data.data.totalCount
                })
            },
            rHandleSizeChange(val) {
                this.rPageNum = 1;
                this.rPageSize = val;
                this.getRechargeList();
            },
            rHandleCurrentChange(val) {
                this.rPageNum = val;
                this.getRechargeList();
            },
            eHandleSizeChange(val) {
                this.ePageNum = 1;
                this.ePageSize = val;
                this.getExchangeList();
            },
            eHandleCurrentChange(val) {
                this.ePageNum = val;
                this.getExchangeList();
            },
            setStartDate() {
                this.startDate = this.$moment(this.startDate).format('yyyy-MM-DD');
            },
            setEndDate() {
                this.endDate = this.$moment(this.endDate).format('yyyy-MM-DD')
            },
            search(){
              this.getRechargeList();
              this.getExchangeList();
            },
        },
        created() {

        },
        watch: {
            uid: {
                handler(newVal) {
                    if (newVal != null && newVal != 0) {
                        this.startDate = this.$moment().subtract(1, 'days').format('yyyy-MM-DD')
                        this.endDate = this.$moment().format('yyyy-MM-DD')
                        this.getRechargeList();
                        this.getExchangeList();
                    }
                },
                immediate: true,
                deep: false
            },
        }
    }
</script>

<style scoped>

</style>